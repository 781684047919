.ticker-logo img {
    max-height: 25px;
    margin-left: 20px;
}

.clickable-ticker-logo img {
    max-height: 25px;
}

.ticker-logo {
    display: flex;
    align-items: flex-start;
    margin-bottom: 10px;
}

.clickable-ticker-logo a {
    cursor: pointer;
    display: inline-block; /* This ensures the clickable area is only around the clickable-ticker-logo */
}

.clickable-ticker-logo a:hover, 
.clickable-ticker-logo a:focus {
    /* Add any hover effects, like a slight scale, shadow, or color change */
    transform: scale(1.05); /* Example effect: Slight increase in size */
}

.company_name {
    font-size: 16px;
    line-height: 1.5;
    font-weight: 600;
    /* font-family: "Nova Mono", Monaco, Consolas, "Liberation Mono", "Courier New", monospace; */
    letter-spacing: .3em;
    color: #f99200;
}