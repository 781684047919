.filter-container {
    display: flex;
    align-items: center;
    gap: 10px;
  }
  
  .filter-dropdown, .rule-dropdown, .filter-value {
    border-radius: 15px;
    font-size: .8rem;
    line-height: 1rem;
    padding: 5px 10px;
    border: 1px solid #ccc;
    outline: none;
  }
  
  .filter-dropdown, .rule-dropdown {
    flex-grow: 0;
    max-width: 150px; /* Adjust max-width as needed */
  }
  
  .filter-value {
    width: 60px; /* Adjust width to fit two integers comfortably */
    text-align: center;
  }
  
  .filter-component-container {
    display: flex;
    align-items: center;
    gap: 10px; /* Adjust the gap between the filter component and the button */
  }
  
  .add-filter-button {
    width: 10px;
    height: 10px;
    padding: 0;
    font-size: 20px; /* Adjust the font size as needed */
    /* Additional styling for the button as needed */
  }
  

  .filter-action {
    letter-spacing: -.3em;
    font-family: "Google Sans",Roboto,Arial,sans-serif;
    font-size: 1.2rem;
    /* font-weight: 500; */
    line-height: 1.5rem;
    color: #2a2a2c;
    text-overflow: ellipsis;
    text-align: left;
    overflow: hidden;
    display: flex;
    -webkit-box-align: center;
    box-align: left;
    min-width:60px;
    align-items: flex-start; /* or another valid value */
}
.filter-action button {
  background-color: inherit;
  color: #535353; /* white text */
  border: none; /* remove default border */
  cursor: pointer; /* pointer cursor on hover */
  border-radius: 8px; /* rounded corners */
  transition: background-color 0.3s; /* smooth transition effect */
}