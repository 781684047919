.stock_trades {
    border-radius: 12px;
    width: 100%;
    padding-bottom: 40px;
    padding: 10px;
}


.stock_trades_content {
    overflow: hidden;
    transition: max-height 0.1s ease-out;
    margin-bottom: 100px;
}


.stock_trade_header {
    display: inline-block;
	font-size: 18px;
	line-height: 1.8;
	font-weight: 500;
	font-family: "Yanone Kaffeesatz", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif;
	letter-spacing: .2em;
	text-transform: uppercase;
    margin-right: 20px;
}

.list-header, .list-row {
    display: flex;
    margin-left: 0px;
}

.list-item-header-no-sort, .list-item-stocks {
    flex: 1;
    /* Add any other styling you need, like padding or margin */
    text-align: left;
    min-width: None;
}

.list-item-header, .list-item-stocks {
    flex: 1;
    /* Add any other styling you need, like padding or margin */
    text-align: left;
    min-width: None;
    min-width: 110px;
}

.list-item-stocks {
    letter-spacing: -.03em;
    font-family: "Google Sans",Roboto,Arial,sans-serif;
    font-size: .875rem;
    line-height: 1.25rem;
    color: #2a2a2c;
    text-overflow: ellipsis;
}

.close-button {
    position: absolute;
    top: 10px;
    right: 10px;
    z-index: 1000; /* high z-index to ensure it's above other content */
    cursor: pointer;
    /* additional styling for the button */
}