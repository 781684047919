body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
      'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
      sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
  

.content {
    max-width: 1172px;
    margin-right: auto;
    margin-left: auto;
    width: 95%;
}

  code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
      monospace;
  }
  
  a {
      color: #013A6F;
      text-decoration: none;
  }
  
  h1 {
      text-align: center;
      color: #535353;
  }

  h4 {
    color: #535353;
  }
  
  h2, .heading-2 {
      font-size: 16px;
      line-height: 1.5;
      font-weight: 600;
      /* font-family: "Nova Mono", Monaco, Consolas, "Liberation Mono", "Courier New", monospace; */
      letter-spacing: .3em;
      color: #aeb1be;
  }
  
  h5 {
    font-size: 12px;
    line-height: 1;
    font-weight: 600;
    /* font-family: "Nova Mono", Monaco, Consolas, "Liberation Mono", "Courier New", monospace; */
    letter-spacing: .1em;
    color: #f99200;
    margin-top: 10px;
    margin-bottom: 5px;
}

  p {
      margin-bottom: 1rem;
      color: #535353;
  }
  
  hr {
      margin: 32px 0;
  }
  
  .button-container {
      display: flex;
      flex-direction: row;
      gap: 20px; /* Adjust as needed */
  }
  
  button, input {
      border-radius: 8px;
      box-sizing: border-box;
      display: block;
      font-size: 16px;
      margin: 8px 0;
      padding: 11px 20px 7px;
      width: 100%;
  }
  
  input {
      border: 1px solid black;
  }
  
  .form-input {
      display: block;
      width: 100%;
      min-height: 40px;
      padding: 5px 15px;
      font-size: 14px;
      font-weight: 400;
      line-height: 28px;
      letter-spacing: .01em;
      background-color: #ebebeb !important;;
      background-image: none;
      border-radius: 6px;
      transition: .3s ease-in-out;
      border: 1px solid #f3f3f9;
  }
  
  .logo {
      display: flex;
      justify-content: center;
      align-items: center;
  }
  
  button {
      border: 1px solid black;
      cursor: pointer;
  }
  
  /* .page-container {
      display: flex;
      background-color: #ddd;
      min-height: 100vh;
      min-width: 100%;
      align-items: center;
      justify-content: center;
  } */

  .page-container {
    display: flex;
    flex-direction: column; /* Stacks header and content vertically */
    background-color: #ddd;
    min-height: 100vh;
    /* align-items: center; */
} 
  
  .list-container {
      background-color: #f5f5f5;
      margin-top: 50px;
      padding: 50px;
      max-width: 1250px;
  }
  
  .list-container-nested {
      max-width: 1250px;
  }
  
  .list-row-nested {
      min-height: 25px;
      display: flex;
      box-pack: justify;
      -webkit-box-pack: justify;
      -webkit-box-align: center;
      box-align: center;
      align-items: center;
  }

  .list-row-nested-header {
    font-weight: 600;
  }
  
  .list-row-nested .list-item:first-child {
      padding-left: 20px; /* Adjust the padding value as per your requirement */
  }
  
  
  .list-title {
      display: inline-block;
      font-size: 18px;
      line-height: 1.8;
      font-family: "Yanone Kaffeesatz", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif;
      letter-spacing: .1em;
      text-transform: uppercase;    
      font-weight: 600;
      margin-bottom: 30px;
      padding-left: 20px;
  }
  
  .list-row {
      border-top: 2px solid #dadce0;
      padding: 15px 0;
      min-height: 43px;
      box-sizing: border-box;
      display: flex;
      box-pack: justify;
      -webkit-box-pack: justify;
      justify-content: space-between;
      -webkit-box-align: center;
      box-align: center;
      align-items: center;
  }
  
  /* .list-row:nth-child(even) {
      background-color: #f5f5f5;
  }
  
  .list-row:nth-child(odd) {
      background-color: #ebebeb;
  } */
  
  
  .list-item {
      letter-spacing: -.03em;
      font-family: "Google Sans",Roboto,Arial,sans-serif;
      font-size: .875rem;
      line-height: 1.25rem;
      color: #2a2a2c;
      text-overflow: ellipsis;
      text-align: left;
      overflow: hidden;
      display: flex;
      -webkit-box-align: center;
      box-align: left;
      min-width:150px;
      align-items: flex-start;
  }

  .list-item-header {
    text-align: left;   
    min-width: 150px;
    cursor: pointer; /* Changes the cursor to a hand when hovering over the element */
    position: relative;
    letter-spacing: .1em;
    box-sizing: border-box;
}
  
  .list-row .list-item:first-child {
      padding-left: 30px; /* Adjust the padding value as per your requirement */
  }
  
  /*.list-header .list-item-header:first-child {
      padding-left: 20px;  
  } */
  
  
  .list-header {
    display: flex;
    font-family: "Google Sans", Roboto, Arial, sans-serif;
    font-size: .9rem;
    font-weight: 900;
    line-height: 1.5rem;
    color: #535353;
    margin-bottom: 15px;
    margin-top: 5px;
    margin-left: 25px;
    box-sizing: border-box; /* Ensure padding & border are inside the box */
  }
  

  .list-item-header-no-sort {
    text-align: left;   
    min-width: 180px;
    letter-spacing: .1em;
    box-sizing: border-box;
    min-width: 150px; /* Adjust as needed */
}
  
  .list-item-header span {
      border-bottom: 1px dotted #535353;
  }
  
  .list-item-header:hover span {
      color: #ffaa00; /* Changes the text color slightly when hovered */
      border-bottom: 1.5px solid #ffaa00; /* Changes the border-bottom color when list-item-header is hovered */
      transform: scale(1.02);
  }
  
  .list-item-header:active {
      color: #e68100; /* Changes the color when the element is active (being clicked) */
  }
  
  .list-item-header:hover::after {
      content: "Click to sort";
      font-size: 0.8rem; 
      position: absolute;
      background: #f5f5f5;
      color: #333;
      padding: 5px;
      bottom: 90%;
      left: 10%;
      white-space: nowrap; 
  }
  
  .link-item span {
      color: #535353;
      border-bottom: 1px dotted #535353;
  }
  
  .link-item:hover span {
      color: #ffaa00; /* Changes the text color slightly when hovered */
      border-bottom: 1.5px solid #ffaa00; /* Changes the border-bottom color when list-item-header is hovered */
      transform: scale(1.02);
  }
  
  .list-item-header.non-clickable {
      cursor: default; /* Changes the cursor back to default */
      border-bottom: none;
      font-size: .9em;
  }
  
  .list-item-header.non-clickable:hover span {
      color: inherit; /* Retains the original color on hover */
      border-bottom: none; /* Retains the original border on hover */
      transform: none; /* No scaling on hover */
  }
  
  .list-item-header.non-clickable:active {
      color: inherit; /* Retains the original color when active */
  }
  
  .list-item-header.non-clickable:hover::after {
      content: ""; /* Removes the hover content */
  }
  
  .list-item button {
      background-color: inherit;
      color: #535353; /* white text */
      border: none; /* remove default border */
      cursor: pointer; /* pointer cursor on hover */
      border-radius: 5px; /* rounded corners */
      transition: background-color 0.3s; /* smooth transition effect */
  }
  
  .list-item button:hover {
      color: #e68100; /* Changes the color when the element is active (being clicked) */
  }
  
  .list-item:button::after {
      content: "Fetch analytics";
      font-size: 0.8rem; 
      position: absolute;
      background: #f5f5f5;
      color: #333;
      padding: 5px;
      bottom: 90%;
      left: 10%;
      white-space: nowrap; 
  }
  
  
  
  .link-item:hover::after {
      content: "Click to edit";
      font-size: 0.8rem; /* Adjust the font size here */
      position: relative;
      background: #f5f5f5;
      color: #333;
      top: 10%;
      left: 20%;
      white-space: nowrap; /* Prevents the text from wrapping to the next line */
  }
  
  .list-row:nth-child(even) .link-item:hover::after {
      background-color: #f5f5f5;
  }
  
  .list-row:nth-child(odd) .link-item:hover::after {
      background-color: #ebebeb;
  }
  
  .error-container {
      display: flex;
      justify-content: center;
      align-items: center; /* This will also vertically center the content */
      flex-direction: column;
      padding: 20px 10px;
      width: 50%;
      margin-left: auto;
      margin-right: auto;
      margin-top: 100px;
  }
  
  .error-container h2, 
  .error-container p {
      width: 80%; /* This will make both h2 and p take up the full width of their parent */
      box-sizing: border-box; /* This ensures padding and border are included in the element's total width */
      text-align: center; 
  }
  
  .content-container {
      background-color: white;
      border-radius: 8px;
      min-width: 300px;
      padding: 32px;
      display: flex;
      justify-content: center;
      align-items: center; /* This will also vertically center the content */
      flex-direction: column;
      padding: 40px 40px;    
  }
  
  .success {
      height: 20px;
      border-radius: 8px;
      margin-bottom: 8px;
      padding: 16px;
      text-align: center;
      color: #177dc4;
      transition: opacity 1s ease-in-out;
      opacity: 1;    
  }
  
  .success.fadeOut {
      opacity: 0;
    }
  
  .fail {
      color: #fc0500;
      border-radius: 8px;
      margin-bottom: 8px;
      padding: 16px;
      text-align: center;
  }
  
  .button-primary, .button-primary:focus {
      color: #ffffff;
      background-color: #f99200;
      border-color: #f99200;
      font-size: 12px;
      line-height: 1;
      letter-spacing: 0.1em;
      vertical-align: middle;
      transition: 250ms all ease-in-out;
      max-width: 250px;
  }
  

/* Additional class to make the button smaller and less bright */
.button-primary.small-muted {
    background-color: #f99200; /* Matted version of #f99200 */
    font-size: 11px;           /* Smaller font size */
    max-width: 200px;          /* Smaller max-width */
    padding: 8px 12px;         /* Adjust padding for a smaller button */
}

  .button-primary:hover, .button-primary:active {
      color: #393939;
      background-color: #ebebeb;
  }
  
  .button-primary:disabled {
      color: #a8a8a8; /* greyed out text */
      background-color: #e0e0e0; /* greyed out background */
      border-color: #c0c0c0; /* greyed out border */
      cursor: not-allowed; /* indicate the button is not interactive */
      opacity: 0.7; /* optionally, you can slightly reduce the opacity */
  }
  
  /* Make sure that hover and active states do not apply to the disabled button */
  .button-primary:disabled:hover, 
  .button-primary:disabled:active,
  .button-primary:disabled:focus {
      color: #a8a8a8; 
      background-color: #e0e0e0; 
      border-color: #c0c0c0;
      cursor: not-allowed;
  }
  
  svg {
      overflow: hidden;
      vertical-align: middle;
  }
  
  .google-login-btn {
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 10px 40px;
      border: none;
      border-radius: 4px;
      cursor: pointer;
      transition: background-color 0.3s;
      background-color: #e0e0e0;
      max-width: 400px;
    }
    
  .google-login-btn:hover {
      background-color: #357ae8;
  }
  
  .google-logo {
      width: 20px;
      height: 20px;
      margin-right: 20px;
  }
  
  .google-login-text {
      color: #535353;
      font-size: 16px;
      font-weight: 500;
  }
  
  .google-login-btn:disabled {
      background-color: #d0d0d0; /* Gray background for disabled state */
      cursor: not-allowed; /* Cursor indicating the button is disabled */
  }
  
  .google-login-btn:disabled .google-logo {
      fill: #b0b0b0; /* Gray color for the logo in disabled state */
  }
  
  .google-login-btn:disabled .google-login-text {
      color: #b0b0b0; /* Gray color for the text in disabled state */
  }
  
  .user {
      padding: 25px;
      box-sizing: border-box;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      max-width: 400px;
      margin: auto;
      background-color: #f5f5f5; /* Optional: Adds a light background color */
      justify-content: center;
      align-items: center;
  }
  
  .user-attr {
      letter-spacing: -.03em;
      font-family: "Google Sans",Roboto,Arial,sans-serif;
      font-size: .875rem;
      font-weight: 500;
      line-height: 1.25rem;
      color: #2a2a2c;
      text-overflow: ellipsis;
      margin-bottom: 10px; /* Adds space between attributes */
      display: flex;
      align-items: center;
      width: 100%;
      margin-top: 20px;
  }
  
  .user-attr label {
      margin-right: 10px;
      width: 150px;
      font-weight: bold;
      text-align: left;
  }
  
  .checkbox-container {
      position: relative;
      display: inline-block; /* To keep label and checkmark side by side */
  }
  
  /* Hide the browser's default checkbox */
  .checkbox-container input {
      position: absolute;
      opacity: 0;
      cursor: pointer;
      height: 0;
      width: 0;
  }
  
  /* Create a custom checkbox */
  .checkmark {
      position: absolute;
      top: 5px;
      left: 160px;
      height: 18px;
      width: 18px;
      background-color: #ebebeb;
  }
  
  /* On mouse-over, add a grey background color */
  .checkbox-container:hover input ~ .checkmark {
      background-color: #e1e1e1;
  }
  

  
  /* Create the checkmark/indicator (hidden when not checked) */
  .checkmark:after {
      content: "";
      position: absolute;
      display: none;
  }
  
  /* Show the checkmark when checked */
  .checkbox-container input:checked ~ .checkmark:after {
      display: inline;
  }
  
  /* Style the checkmark/indicator */
  .checkbox-container .checkmark:after {
      left: 6px;
      top: 3px;
      width: 5px;
      height: 10px;
      border: solid #f99200;
      border-width: 0 2px 2px 0;
      -webkit-transform: rotate(45deg);
      -ms-transform: rotate(45deg);
      transform: rotate(45deg);
  } 
  
.filter-attr label {
    position: relative;
    display: flex; /* Use flexbox for layout */
    align-items: center; /* Vertically center the content */
    font-weight: bold;
    text-align: left;
    margin-right: 140px;
    width: 120px;
}

  
.options-checkmark {
    position: relative;
    top: 0;
    left: 20px; /* Position the checkmark to the right of the label */
    height: 18px;
    width: 18px;
    background-color: #ebebeb;
}


/* Show the checkmark when checked */
.checkbox-container input:checked ~ .options-checkmark:after {
    display: inline;
    z-index: 1;
}
  /* Style the checkmark/indicator */
  .checkbox-container .options-checkmark:after {
    left: 6px;
    top: 3px;
    width: 5px;
    height: 10px;
    border: solid #f99200;
    border-width: 0 2px 2px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
}

.checkbox-container:hover input:checked ~ .options-checkmark {
    background-color: #e1e1e1;
}

  /* Create the checkmark/indicator (hidden when not checked) */
  .options-checkmark:after {
      content: "";
      position: absolute;
      display: none;
  }

  .animate_progress_bar {
      animation: progress-animation 2s infinite;
    }
    
    @keyframes progress-animation {
      0% {
        width: 0;
      }
      50% {
        width: 100%;
      }
      100% {
        width: 0;
      }
    }
    
  .download-link a {
      font-size: 12px;
      font-weight: 500;
      font-family: "Yanone Kaffeesatz", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif;
      letter-spacing: .3em;
      text-decoration: none;
  }
  
  .download-link a {
      border-bottom: 1px dotted #535353;
  }
  .download-link a:hover {
      color: #ffb041;
      text-decoration: underline;
  }
  
  .download-link a, .download-link a:focus, .download-link a:active {
      color: inherit;
  }
  
  .scrollable-div {
      height: 100vh; /* or any specific height */
      overflow-y: auto; /* This will add a vertical scrollbar to the div */
  }

  a:hover {
    color: #ffb041;
    text-decoration: underline;
  }

  /* a:visited {
    color: #535353;
  } */

  .down {
    letter-spacing: .00625em;
    font-family: "Google Sans",Roboto,Arial,sans-serif;
    padding: 0 8px;
    border-radius: 8px;
    color: #9d4d4d;
    margin-right: -8px;   
  }

  .up {
    letter-spacing: .00625em;
    font-family: "Google Sans",Roboto,Arial,sans-serif;
    padding: 0 8px;
    border-radius: 8px;
    color: #137333;
    margin-right: -8px;
  }

  .container-stockpicks {
    padding-top: 5px;
    padding-bottom: 5px;
    margin-right: 600px  ;
    background-color: #ebebeb;
    border-radius: 8px;
  }

/* Base styling for all exchange containers */
.exchange-container {
    padding: 10px;
    margin: 10px 0;
    border-radius: 5px;
    transition: background-color 0.3s ease; /* smooth transition for the background color */
}

/* Specific styling for NYSE */
.exchange-container.NYSE {
    background-color: #ebebeb;  /* Light Blue */
}

/* Specific styling for NYSEARCA */
.exchange-container.NYSEARCA {
    background-color: #ebebeb;  /* Light Green */
}

/* Specific styling for NASDAQ */
.exchange-container.NASDAQ {
    background-color: #ebebeb;  /* Light Red */
}

/* Add any other styles or exchanges as needed */
/* Hide the browser's default radio button */
.radio-container input[type="radio"] {
    position: absolute;
    opacity: 0;
    cursor: pointer;
  }
  
  /* Create a custom radio button */
  .options-radio {
    position: absolute;
    left: 100px;
    height: 11px;
    width: 11px;
    background-color: #ebebeb;
    border-radius: 100%;
  }
  
  /* Style the indicator (the dot inside the radio button) */
  .radio-container input[type="radio"]:checked ~ .options-radio:after {
    content: "";
    position: absolute;
    display: block;
    top: 2px;
    left: 2px;
    width: 7px;
    height: 7px;
    border-radius: 50%;
    background: #f99200;
  }
  
  /* Style the radio button on hover */
  .radio-container:hover input ~ .options-radio {
    background-color: #ccc;
  }
  
  /* Style the radio button when the radio button is checked */
  .radio-container input:checked ~ .options-radio {
    background-color: #ebebeb;
  }
  
  .list-row-clickable {
        cursor: pointer;
        &:hover {
            background-color: #ebebeb; /* Simple hover effect */
        }
    }
 
    .list-row-clickable.touched {
        background-color: #ebebeb; /* Same effect as hover */
    }

    .list-row-selected {
        background-color: #f7dab7; /* Simple hover effect */
        transform: translate3d(0, 0, 0); /* Trigger GPU acceleration */
    }
        



/* Responsive Typography */
body, header, nav, div {
    font-size: .8rem; /* Base font size */
}

/* Media Query for Tablets */
@media (max-width: 768px) {
    .content, header {
        padding: 10px;
    }

    header, .navbar-container, .navbar-grid, .navbar-list-link {
        display: flex;
        flex-direction: column;
    }

    .navbar-list-link li {
        margin-bottom: 10px; /* Space out nav items */
    }
}

/* Media Query for Mobile */
@media (max-width: 480px) {
    body, header, nav, div {
        font-size: 0.9rem; /* Slightly smaller font size */
    }

    header {
        padding: 5px;
    }

    .navbar-list-link li {
        margin-bottom: 5px;
    }

    /* Add more mobile-specific styles here */
}

.login-container {
    margin: auto; /* Additional centering for safety */
    padding: 20px; /* Optional padding for aesthetics */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Optional shadow for a subtle depth effect */
    border-radius: 8px; /* Optional border radius for rounded corners */
    background-color: #fff; /* Optional background color */
    min-width: 300px;
    max-width: 500px;
}

.login-container h2 {
    text-align: center; 
}


hr {
    border: 1px solid #013A6F; /* Adds a red border */
    margin-top: 30px;      /* Adds 20px margin at the top */
    margin-bottom: 30px;   /* Adds 20px margin at the bottom */
}

.feedback-item {
    letter-spacing: -.03em;
    font-family: "Google Sans",Roboto,Arial,sans-serif;
    font-size: 1rem;
    line-height: 2.2rem;
    color: #2a2a2c;
    text-align: left;
    overflow: hidden;
    display: flex;
    -webkit-box-align: center;
    box-align: left;
    min-width:150px;
    align-items: flex-start;
}

.styled-paragraph {
    color: #535353;           /* Sets the text color */
    font-size: 16px;       /* Sets the font size */
    line-height: 1.6;      /* Sets the line height for better readability */
    text-align: justify;   /* Justifies the text for a clean look */
    margin: 15px 0;        /* Adds top and bottom margins */
    padding: 10px;         /* Adds padding for some space around the text */
    max-width: 600px;      /* Sets a max-width to control line length for readability */
    border-radius: 8px;    /* Rounds the corners of the border */
    font-family: 'Arial', sans-serif; /* Sets a clean and readable font */
}


.feedback-list {
    width: 90%;
}

/* .feedback-list > div:nth-child(odd) {
    background-color: #f0f0f0; 
} */

/* .feedback-list > div:nth-child(even) {
    box-shadow: 0 4px 8px rgba(1, 1, 0, 0.1); 
} */
