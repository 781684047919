.list-header-item-corr {
    text-align: center;   
    cursor: pointer; /* Changes the cursor to a hand when hovering over the element */
    position: relative;
    letter-spacing: .1em;
    box-sizing: border-box;
    font-size: .875rem;
    white-space: nowrap;
    text-overflow: ellipsis;
}

.list-item-corr {
    letter-spacing: -.03em;
    font-family: "Google Sans",Roboto,Arial,sans-serif;
    font-size: .875rem;
    line-height: 1.25rem;
    color: #2a2a2c;
    text-overflow: ellipsis;
    text-align: center;
    overflow: hidden;
    display: flex;
    -webkit-box-align: center;
    box-align: left;
    align-items: flex-start;
    justify-content: center;
}

.list-row-corr {
    border-top: 2px solid #dadce0;
    padding: 15px 0;
    min-height: 43px;
    box-sizing: border-box;
    display: flex;
    box-pack: justify;
    -webkit-box-pack: justify;
    justify-content: space-between;
    -webkit-box-align: center;
    box-align: center;
    align-items: center;
}

.list-header-corr, .list-row-corr {
    display: flex;
    justify-content: space-between;
}

.list-item-header-corr, .list-item-corr {
    flex: 1;
    text-align: center;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.list-item-corr, .list-header-item-corr {
    flex: 1;
    flex-shrink: 1;
    min-width: 40px; 
}

.grouped-header {
    display: flex;
    justify-content: space-between;
}

.grouped-header-item {
    flex: 1;
    text-align: center;
    /* Other styling as needed */
}
