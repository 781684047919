.stock_trades {
    border-radius: 12px;
    width: 110%;
    padding-bottom: 40px;
    padding: 10px;
}


.stock_trades_content {
    overflow: hidden;
    transition: max-height 0.1s ease-out;
    margin-bottom: 100px;
}


.stock_trade_header {
    display: inline-block;
	font-size: 18px;
	line-height: 1.8;
	font-weight: 500;
	font-family: "Yanone Kaffeesatz", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif;
	letter-spacing: .2em;
	text-transform: uppercase;
    margin-right: 20px;
}

.list-header, .list-row {
    display: flex;
    margin-left: 0px;
}

.list-item-header-no-sort, .list-item-stocks {
    flex: 1;
    text-align: center; /* Optional, for centering text */
    /* Add any other styling you need, like padding or margin */
    text-align: left;
    min-width: None;
}

.list-item-stocks {
    letter-spacing: -.03em;
    font-family: "Google Sans",Roboto,Arial,sans-serif;
    font-size: .875rem;
    line-height: 1.25rem;
    color: #2a2a2c;
    text-overflow: ellipsis;
}

.list-item-stocks button {
    background-color: inherit;
    color: #535353; /* white text */
    border: none; /* remove default border */
    cursor: pointer; /* pointer cursor on hover */
    border-radius: 5px; /* rounded corners */
    transition: background-color 0.3s; /* smooth transition effect */
    padding: 0px;
    margin: 0px;
}