.breadcrumb {
    list-style: none; /* Removes the default numbering */
    padding: 0; /* Adjusts padding */
    display: flex; /* Uses flexbox for alignment */
    align-items: center; /* Aligns items vertically */
  }
  
  .breadcrumb-item {
    display: inline-block; /* Displays items inline */
    margin-right: 8px; /* Adds some space to the right of each item */
    margin-left: 5px;;
  }
  
  .breadcrumb-item:last-child {
    margin-right: 0; /* Removes margin from the last item */
  }
  
  .breadcrumb-item span {
    margin-right: 20px; /* Adds space after the separator */
  }
  
  .crumb-container {
    margin-left: 10px;
    margin-bottom: 10px;
  }