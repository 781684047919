.feedback-form {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%; /* Adjust width as necessary */
    max-width: 400px; /* Adjust max-width as necessary */
    margin: 0 auto; /* Center the form in the middle of the page */
    padding: 20px; /* Optional padding */
}

.form-input, .form-textarea {
    width: 100%; /* Make inputs take full width of the form */
    margin-bottom: 10px; /* Space between inputs */
    box-sizing: border-box; /* Include padding and border in the element's total width and height */
}

.form-textarea {
    height: 4em; /* Adjust to match the height of three lines of text */
    font-family: inherit; /* To match the font style of the rest of your form */
    font-size: 1em; /* Adjust the font size as necessary */
    resize: none; /* Optional: Prevents resizing of the textarea */
}

.feedback-title {
    line-height: 1.1;
    font-weight: 400;
    color: #e68100;
}