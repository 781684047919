header {
  display: flex; /* Use flexbox for alignment */
  justify-content: space-between; /* Space out logo and navbar */
  align-items: center; /* Align items vertically in the center */
  margin-top: 20px;
  width: 100%;
  padding: 20px;
  max-width: 1170px;
  margin-left: auto;
  margin-right: auto;
}

.navbar-container {
  /* Removed float and added necessary flex properties */
  height: auto;
  min-height: 100px;
  position: relative;
}

nav {
    display: flex;
    margin-left: 50px;
}

nav ul {
    list-style-type: none;
    margin: 0;
    padding: 0;
    display: flex;
}

nav li {
    margin: 0 10px;
}

.navbar-list-link {
    text-align: left;
    font-size: 0;
    line-height: 0;    
}

.navbar-list-link a:hover {
    color: #ffb041;
    text-decoration: underline;
}

.navbar-list-link li  {
	display: inline-block;
	position: relative;
    text-align: center;
    vertical-align: top;  
}

.navbar-list-link li a {
    line-height: normal; /* Adjust line height if necessary for appearance */
}

/* .navbar-grid {
  display: none; 
  flex-direction: column;
  position: absolute; 
  top: 100%; 
  right: 0; 
  background-color: #fff; 
  width: 100%; 
  box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2); 
  z-index: 1; 
} */

.navbar-grid {
  display: flex; /* or block, depending on your original layout */
  /* Your existing styles for desktop navbar layout */
  position: static; /* Reset to normal flow */
  width: auto; /* Reset width */
  background-color: transparent; /* Reset background */
  box-shadow: none; /* Reset box-shadow */
  z-index: auto; /* Reset z-index */
}

  .navbar-list-link {
    list-style: none;
    display: flex;
    align-items: flex-start;
    gap: 10px; /* Adjust the gap as needed */
    padding: 0;
    margin: 0;
    font-size: 18px;
    line-height: 1.1;
    font-weight: 600;
    font-family: "Yanone Kaffeesatz", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif;
    letter-spacing: .1em;
    text-transform: uppercase; 
  }
  
  .user-welcome {
    grid-column: 1 / -1;
    text-align: left; 
    margin-left: 10px;
    margin-top: 20px;
    font-size: 18px;
    line-height: 1.8;
    font-weight: 600;
    font-family: "Yanone Kaffeesatz", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif;
    letter-spacing: .3em;
    color: #e68100;
  }

  .dropdown .dropbtn {
    border: none;
    outline: none;
    background-color: inherit;
    font-family: inherit;
    margin: 0;
    overflow: visible; /* Make sure the dropdown content is not clipped */
    padding: 5px; /* Adjust padding as needed */    
    position: relative; 
  }
  .dropdown-content {
    display: none;
    box-sizing: border-box;
    position: absolute;
    z-index: 1;
    overflow: visible; /* Make sure the dropdown content is not clipped */
    padding: 5px; /* Adjust padding as needed */
    line-height: 5em; /* Resets line height */
    padding: 5px; /* Adds padding, adjust as needed */
    margin: 0;
    width: auto;
    height: auto; 
  }
  
  .dropdown-content a {
    float: none;
    text-decoration: none;
    display: block;
    text-align: left;
    line-height: normal; 
    display: block; /* Makes each link a block for better control */
    margin: 0; /* Resets any inherited margin */
    padding: 5px 10px; /* Adds padding, adjust as needed */  
    overflow-x: auto; /* Allows for horizontal scrolling if needed */
    white-space: nowrap; /* Prevents breaking of items into multiple lines */
  }
  
  .dropdown-content a:hover {
    text-decoration: underline;
  }
  
  .dropdown:hover .dropdown-content {
    display: block;
  }

/* Hamburger Menu Style */
.hamburger {
  display: none;
  cursor: pointer;
  background: none;
  border: none;
  padding: 0;
}

.hamburger-line {
  display: block;
  width: 30px;
  height: 3px;
  background-color: #333; /* Change color as needed */
  margin: 5px 0;
  transition: all 0.3s ease;
}

/* Hover effect */
.hamburger:hover .hamburger-line {
  background-color: #666; /* Change color for hover effect */
}

/* Media Query for Smaller Screens */
@media screen and (max-width: 768px) {
  .hamburger {
      display: block;
  }

  .navbar-grid {
    display: none; /* Initially hidden on smaller screens */
    flex-direction: column;
    position: absolute;
    top: 50%;
    left:10%;
    right: 0;
    width: 100%; /* Adjust as needed for mobile layout */
    z-index: 1;
}

  .navbar-list-link {
      display: flex;
      flex-direction: column;
      padding: 0;
  }

  .show {
      display: flex;
  }

  header {
    flex-direction: row; /* Keep logo and navbar in a row */
  }

  .logo, .navbar-container {
    margin: 20px;
    /* width: auto; */
  }

  .navbar-container {
    flex: 1; 
    order: 2; /* Put navbar-container second in the order */
  }

  .logo {
    order: 1; /* Put logo first in the order */
  } 
  nav {
    justify-content: flex-end; /* Align hamburger menu to the right */
}
}
