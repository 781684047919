.ticker_details {
    border: 1px #c0c0c0 solid;
    border-radius: 12px;
    width: 40%;
    padding-bottom: 40px;
    padding: 10px;
}

.ticker_details_wide {
    width: 80%;
    margin-top: 40px;
    margin-bottom: 40px;
}

.ticker_details_head {
    cursor: pointer;
    display: flex;
    outline: none;
    padding: 16px 0;
    transition: background .2s .1s;    
}

.ticker_details_title {
    display: inline-block;
    min-width: 100px;
    font-family: "Google Sans",Roboto,Arial,sans-serif;
    font-size: 1.25rem;
    font-weight: 400;
    letter-spacing: 0;
    line-height: 1.5rem;
    color:#f99200;
    padding-bottom: 20px;
}

.ticker_details_content {
    overflow: hidden;
    transition: max-height 0.1s ease-out;
}

.ticker_details_desc {
    letter-spacing: .025em;
    font-family: Roboto,Arial,sans-serif;
    font-size: .8rem;
    font-weight: 400;
    padding-bottom: 16px;
    line-height: 1.7;
    overflow-wrap: break-word;
    word-wrap: break-word;
}

.ticker_details_row {
    border-top: 1px solid #dadce0;
    padding: 4px 0;
    min-height: 30px;
    box-sizing: border-box;
    display: flex;
    box-pack: justify;
    -webkit-box-pack: justify;
    justify-content: space-between;
    -webkit-box-align: center;
    box-align: center;
    align-items: center;
}

.ticker_details_row_lbl {
    display: flex;
    -webkit-box-align: center;
    box-align: center;
    align-items: center;
    letter-spacing: .07272727em;
    font-family: Roboto,Arial,sans-serif;
    font-size: .6875rem;
    font-weight: 500;
    text-transform: uppercase;
    color: #5f6368;
    line-height: .6875rem;
    margin-right: 8px;
    margin-bottom: 5px;
    white-space: nowrap;    
}
.ticker_details_row_cnt {
    letter-spacing: -.03em;
    font-family: "Google Sans",Roboto,Arial,sans-serif;
    font-size: .875rem;
    font-weight: 500;
    line-height: 1.25rem;
    color: #2a2a2c;
    text-overflow: ellipsis;
    text-align: right;
    overflow: hidden;
    display: flex;
    -webkit-box-align: center;
    box-align: center;
    align-items: center;
}

.intraday_trends {
    border: 1px #c0c0c0 solid;
    border-radius: 12px;
    width: 60%;
    padding-bottom: 40px;
    padding: 10px;
    overflow-y: auto;
    max-height: 300px;
}

.ticker_trades {
    display: flex;
    gap: 20px;
}

h6 {
    margin-bottom: 5px;
    padding: 0;
}