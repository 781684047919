.upcoming-container {
    display: flex;
    flex-direction: column; /* Stack elements vertically on small screens */
    justify-content: flex-start;
}

.upcoming-trades {
    width: 100%; /* Full width on small screens */

    /*
    No need for scroll bar on smaller screen
    overflow-y: auto;
    */
    margin-top: 20px;
}

.trade-charts {
    width: 100%; /* Full width on small screens */
    /* Additional styling for trade-charts */
}

/* Media Query for larger screens */
@media (min-width: 768px) { /* Adjust this value based on your breakpoint needs */
    .upcoming-container{
        flex-direction: row; /* Side-by-side on larger screens */
    }

    .upcoming-trades {
        width: 35vw; /* Adjust width based on viewport width */
        max-width: 35%; /* Split space equally on larger screens */
        overflow-y: auto;
        max-height: 300px;
        margin-right: 25px; /* Half of total desired space between */
    }

    .trade-charts {
        flex-grow: 1; /* Allow this element to take up remaining space */
        width: calc(65vw - 30px); /* Remaining width minus any margins/paddings */
        max-width: 55%;
        margin-left: 25px; /* Other half of total desired space between */
    }

    .bar-charts {
        max-height: 250px; /* Maximum height */
        overflow: hidden; /* Optional: to clip the excess if chart exceeds this height */
        margin-left: 100px;
        margin-right: 100px;
        margin-bottom: 10px;
        border-bottom: 1px solid #013A6F;
    }

    .section {
        border-right: 3px solid #013A6F;
        box-sizing: border-box; /* Include border in the element's width */
    }    
}

.open-trade-details-wrapper {
    display: block;
}

.live-charts {
    width: 80%; 
}
.ticker-details-wrapper {
    width: 100%;
}

.upcoming-trades::-webkit-scrollbar {
    width: 5px; /* Adjust the width of the scrollbar */
}

.upcoming-trades::-webkit-scrollbar-thumb {
    background: #888; /* Color of the scrollbar thumb */
    border-radius: 5px; /* Rounded corners for the scrollbar thumb */
}

.upcoming-trades::-webkit-scrollbar-thumb:hover {
    background: #555; /* Color of the scrollbar thumb on hover */
}

.ticker_option {
    display: flex;
    -webkit-box-align: center;
    box-align: center;
    align-items: center;
    box-pack: justify;
    -webkit-box-pack: justify;
    justify-content: space-between;
    padding: 8px 20px;
    cursor: pointer;    
    background-color: #ebebeb;
    width: 400px;
    border-bottom: 1px solid #dadce0;
}

.ticker_exchange {
    letter-spacing: .015em;
    font-family: "Google Sans",Roboto,Arial,sans-serif;
    font-size: .675rem;
    line-height: 1rem;
    font-weight: 200;
}

.ticker_symbol {
    letter-spacing: .07272727em;
    font-family: Roboto,Arial,sans-serif;
    font-size: .6875rem;
    font-weight: 500;
    line-height: 1rem;
    text-transform: uppercase;
    text-align: left; 
}

.larger {
    font-size: .775rem;
    font-weight: 500;
    line-height: 1.1rem;
}

.ticker_name {
    letter-spacing: .01785714em;
    font-family: "Google Sans",Roboto,Arial,sans-serif;
    font-size: .875rem;
    line-height: 1.25rem;
    font-weight: 400;
    float: left;
}

.ticker_option:hover {
    cursor: pointer;
    background-color: #f7dab7; /* Example background color on hover */
}

.ticker_option.selected {
    background-color: #f7dab7; /* Background color for the selected item */
}

.trades-section-title {
    display: inline-block;
    min-width: 100px;
    font-family: "Google Sans",Roboto,Arial,sans-serif;
    font-size: 1.25rem;
    font-weight: 600;
    letter-spacing: 0;
    line-height: 1.5rem;
    letter-spacing: 2px;
    color:#f99200;
    margin: 30px 0;
}

.open-trade {
    display: flex;
    align-items: center; /* Align items vertically centered */
    padding: 8px 20px;
    font-family: "Google Sans",Roboto,Arial,sans-serif;
    font-size: .875rem; /* Consider starting with a slightly larger font-size */
    line-height: 1.5rem;
    text-overflow: ellipsis;
    color: #3c4043;
    width: 80%;
}

.company-info {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    margin-right: 20px; /* Adjust margin for spacing */
    flex: 1;
}

.trade-info {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    flex: 1;
    margin-right: 20px;
}

.last-price {
    margin-right: 10px; /* Adjust the value as needed */
    font-size: .875rem;
}

.open-price  {
    margin-right: 10px; /* Adjust the value as needed */
    font-size: .875rem;
}

.expand-open-trade {
    letter-spacing: -.03em;
    font-family: "Google Sans",Roboto,Arial,sans-serif;
    font-size: .875rem;
    line-height: 1.25rem;
    color: #2a2a2c;
    text-overflow: ellipsis;
    text-align: left;
    overflow: hidden;
    display: flex;
    -webkit-box-align: center;
    box-align: left;
    align-items: flex-start;
}

.expand-open-trade button {
    background-color: inherit;
    color: #535353; /* white text */
    border: none; /* remove default border */
    cursor: pointer; /* pointer cursor on hover */
    border-radius: 5px; /* rounded corners */
    transition: background-color 0.3s; /* smooth transition effect */
} 

@media screen and (max-width: 600px) {
    .open-trade {
        flex-direction: row; /* Stack elements vertically on small screens */
        align-items: center;
    }

    .company-info, .trade-info {
        width: 100%; /* Full width for each section */
        margin-bottom: 10px; /* Add bottom margin for spacing between sections */
        display: flex;
        flex-direction: column; /* Keep elements in a row */
    }

    .company-info {
        width: 40%; /* Fixed width for company-info */
        justify-content: flex-start;
        justify-content: space-between; 
        padding-right: 10px; /* Add padding to create spacing between .company-info and .trade-info */
    }

    .trade-info {
        width: 40%; 
        align-items: flex-start; /* Align trade info to the end */
        align-items: flex-start; /* Align trade info to the start */
    }

    .open-price, .last-price, .ticker_name, .ticker_symbol, .ticker_exchange {
        font-size: 0.75rem; /* Decrease font size for smaller screens */
    }
   
}

@keyframes flash-green {
    0% { background-color: transparent; }
    50% { background-color: lightgreen; } /* Green for positive change */
    100% { background-color: transparent; }
}

@keyframes flash-red {
    0% { background-color: transparent; }
    50% { background-color: lightcoral; } /* Red for negative change */
    100% { background-color: transparent; }
}

/* Styles for updated price with positive and negative changes */
.updated-positive {
    animation: flash-green 1s; /* Runs for 1 second */
}

.updated-negative {
    animation: flash-red 1s; /* Runs for 1 second */
}

.no-wrap-span {
    white-space: nowrap; /* Prevents the text from wrapping */
    overflow: hidden; /* Optional: hides text that overflows the container's bounds */
    text-overflow: ellipsis; /* Optional: adds an ellipsis (...) to text that overflows */
}