.collapsible {
    cursor: pointer; 
    
  }
  
.collapsible.active {
    content: '\002B';
    /* color: #f99200; */
    font-weight: bolder;
    float: right;
    margin-left: 690px;
}

.collapsible_content {
    overflow: hidden;
    transition: max-height 0.2s ease-out;
    max-height: 0; /* Start in a collapsed state */
  }

  .collapsible-toggle-icon {
    margin-right: 8px; /* Adjust as needed */
    padding-left: 10px; /* Adjust the value as needed */
  }

  .collapsible_title {
    display: inline-block;
    min-width: 100px;
    font-family: "Google Sans",Roboto,Arial,sans-serif;
    font-size: 1.25rem;
    font-weight: 400;
    letter-spacing: 0;
    line-height: 1.5rem;
    color:#f99200;
    padding-bottom: 20px;
}