.loading-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;
}

.loader {
    border: 5px solid #f3f3f3;
    border-top: 5px solid #013a6f;
    border-radius: 50%;
    width: 50px;
    height: 50px;
    animation: spin 1s linear infinite;
    margin-top: 10px;
    margin-bottom: 10px;
}

@keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}


/* Responsive adjustments */
@media (max-width: 600px) {
    .loader {
        width: 30px;
        height: 30px;
        border-width: 3px;
    }
}