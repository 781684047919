.logo img {
    max-height: 75px;
}

.clickable-logo img {
    max-height: 75px;
}

/* .logo {
    float: left; /
} */

.clickable-logo a {
    cursor: pointer;
    display: inline-block; /* This ensures the clickable area is only around the clickable-logo */
}

.clickable-logo a:hover, 
.clickable-logo a:focus {
    /* Add any hover effects, like a slight scale, shadow, or color change */
    transform: scale(1.05); /* Example effect: Slight increase in size */
}

/* Responsive adjustments */
@media (max-width: 600px) {
    .logo img {
        max-height: 60px; /* Smaller logo for mobile */
        margin-bottom: 20px;
    }
}